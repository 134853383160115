import {  createSlice } from "@reduxjs/toolkit";
import { LOGIN_STATE } from "../../utils/Constants";


export const UserSlice = createSlice({
  name: "user",
  initialState: {
        email :'',
        accessToken : '',
        refreshToken : '',
        accessTokenExpiry : 0,
        refreshTokenExpiry : 0,
        loginState : '' //yes-no-maybe
  },
  reducers: {
    setUser : (state, action)=>{
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.accessTokenExpiry = action.payload.accessTokenExpiry;
      state.refreshokenExpiry = action.payload.refreshTokenExpiry;
      state.username = action.payload.email;
    },
    setTokens : (state, action)=>{
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.accessTokenExpiry = action.payload.accessTokenExpiry;
      state.accessTokenExpiry = action.payload.accessTokenExpiry;
    },
    setUserLoginState : (state,action)=>{
      state.loginState = action.payload.loginState
    },
    removeUser : (state,action)=>{
        state = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase('persist/REHYDRATE', (state, action) => {
        if(action && action.payload && action.payload.user){
          let user = action.payload.user;
          if(user.loginState === LOGIN_STATE.yes){
            state.loginState = LOGIN_STATE.maybe;
            state.accessToken = user.accessToken;
            state.refreshToken = user.refreshToken;
            state.accessTokenExpiry = user.accessTokenExpiry;
            state.refreshTokenExpiry = user.refreshTokenExpiry;
          }
        }
    },
    );
  },
});

export const {setUser, removeUser} =  UserSlice.actions;
export default UserSlice.reducer;