import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter, GridToolbarFilterButton, GridToolbarColumnsButton } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import * as CONSTANTS from "../../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import DashboardFilter from './DashboardFilter';
import { createFilterQuery, mapCancelParty, mapDeliveryPartnerName, mapInternalStatus} from '../util';
import { defaultTheme } from '../../../styles/Theme';
import { getRecords } from '../../../services/ReportService';
import { Button, Menu, MenuItem, Tooltip, createSvgIcon } from '@mui/material';
import { removeAppBaseUrlForTracking } from '../../../utils/Utils';
import { saveAs } from 'file-saver';
import CancellationModal from '../../Track/components/CancellationModal';

const styles = {
  headerStyle: {
    fontWeight: 'bold',
    marginTop: '2%',
    marginBottom: '2%',
    color: defaultTheme.palette.primary.main
  },
  dashboardStyle: {
    backgroundColor: defaultTheme.palette.primary.contrastText,
    marginBottom: '2%'
  },
  dataGridStyle: {
    boxShadow: 1,
    border: 1,
    borderColor: defaultTheme.palette.primary.contrastText,
    '& .MuiDataGrid-cell:hover': {
      color: defaultTheme.palette.primary.main,
    },
  }
}

export function Table({distinctSearchFilters}) {
  const dispatch = useDispatch();
  const _user = useSelector((state) => state.user)
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
    isFilterSelected: false
  })
  const [filterData, setFilterData] = useState({});
  const [cancellationState, setCancellationState] = useState({
    isCancelModalOpen: false,
    isCancelled: false,
    logisticsId: null,
    partnerName: null
  });
  
  const handleClose = (lmsOrderId) => {
    if (lmsOrderId) {
      setPageState(old => ({ ...old, data: old.data.map(order => {
        if (order.id === lmsOrderId) {
          order.c7 = 'Cancelled';
          return order;
        } else { return order; }
      })}));
    }
    
    setCancellationState({...cancellationState, isCancelModalOpen: false})
  }

  const displayText = (cellValue) => {
    return (
      <Tooltip title={cellValue}>
        <div>{cellValue}</div>
      </Tooltip>
    );
  };

  const displayActions = (cellValue, tooltipText) => {
    return (
      <Tooltip title={tooltipText}>
        <div>{cellValue}</div>
      </Tooltip>
    );
  };

  const handleCancel = (params) => {
    console.log('Cancel clicked', params);
    setCancellationState({...cancellationState, isCancelModalOpen: true, logisticsId: params.id, partnerName: params.row.c4});
  }

  const columns = [
    {
      field: 'actions', headerName: 'Actions', width: 110,
      description: 'User actions on the orders.',
      renderCell: (params) => {
        if (CONSTANTS.LAST_OR_COMPLETED_STATUS.includes(params.row.c7)) {
          return displayActions(
            <Button variant="contained" color="error" disabled size="small">Cancel</Button>,
            'Order can not cancel now'
          )
        } else {
          return displayActions(
            <Button variant="contained" color="error" size="small" onClick={() => handleCancel(params)}>Cancel</Button>,
            'Click to cancel order'
          )
        }
        
      }
    },
    {
      field: 'id', headerName: 'LMS ID', width: 300,
      description: 'The identification used by the logistics service.',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c1', headerName: 'CRM ID', width: 200,
      description: 'The Claim number of the claims service.',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c2', headerName: 'Product Type', width: 240,
      description: 'The fullfilment type of the claims service.',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c3', headerName: 'Carrier Order ID', width: 150,
      description: 'The Order ID of the Delivery partner.',
      renderCell: (params) => displayText(<a href={params.row.c20 ? removeAppBaseUrlForTracking(params.row.c20) : params.value}>{params.value}</a>)
    },
    {
      field: 'c4', headerName: 'Carrier', width: 120,
      description: 'The delivery partner assigned.',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c5', headerName: 'Country', width: 100,
      description: 'Country',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c6', headerName: 'Order Time', width: 200,
      description: 'Delivery pick up schedule time',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c7',
      headerName: 'Internal Status', width: 150,
      description: 'The status of the logistics service.',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c8', headerName: 'Carrier status', width: 170,
      description: 'order status of the delivery partner',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c9', headerName: 'Cancellation Reason', width: 160,
      description: 'Cancellation Reason',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c10', headerName: 'Cancellation Party', width: 160,
      description: 'Cancellation Party',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c11', headerName: 'Source Name', width: 140,
      description: 'Source Name',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c12', headerName: 'Source Address', width: 160,
      description: 'Source Address',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c13', headerName: 'Source Phone', width: 150,
      description: 'Source Phone',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c14', headerName: 'Recipient Name', width: 150,
      description: 'Recipient name',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c15', headerName: 'Recipient Address', width: 160,
      description: 'Recipient Address',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c16', headerName: 'Recipient Phone', width: 140,
      description: 'Recipient Phone',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c17', headerName: 'Order Amount', width: 100,
      description: 'Order Amount',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c18', headerName: 'Notes', width: 250,
      description: 'Notes for delivery',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c19', headerName: 'Delivery Type', width: 160,
      description: 'Delivery Type',
      renderCell: (params) => displayText(params.value)
    },
    {
      field: 'c20', headerName: 'Tracking ID', width: 450,
      description: 'Tracking ID',
      renderCell: (params) => displayText(<a href={params.row.c20 ? removeAppBaseUrlForTracking(params.row.c20) : params.value}>{params.value}</a>)
    }
  ];
  
  const handleExport = async () => {
    const storename = _user.username;

    setPageState(old => ({ ...old, isLoading: true }));

    let searchParameters = createFilterQuery(filterData);
    const response = await getRecords(searchParameters, 0, pageState.total, _user, dispatch, storename)

    try {
      //CS-1866
      let fileUrl = response.data;
      if(fileUrl !== undefined || fileUrl !== "") {
        window.open(fileUrl); //trigger download file
      }
    } catch (ex) {
      console.error('HandleExport exception to : ', ex)
    } finally {
      setPageState(old => ({ ...old, isLoading: false, total: response.total }));
    }
  };

  const transformDbDataToGrid = (logistics) => {
    let rows = [];
    if (logistics.length > 0) {
      for (let i in logistics) {

        let currencyCode = CONSTANTS.CURRENCY_CODES[logistics[i].country];

        let temp = {
          id: logistics[i].id,
          c1: logistics[i].claimId,
          c2: logistics[i].claimType,
          c3: logistics[i].deliveryPartner.orderId,
          c4: mapDeliveryPartnerName(logistics[i].deliveryPartner.name),
          c5:logistics[i].country,
          c6: logistics[i].scheduleDateTime,
          c7: mapInternalStatus(logistics[i].status),
          c8: logistics[i].deliveryPartner.deliveryStatus,
          c9: logistics[i].cancellationReason,
          c10: mapCancelParty(logistics[i].cancelParty),
          c11: logistics[i].pickup !== undefined ? logistics[i].pickup.name : "",
          c12: logistics[i].pickup !== undefined ? logistics[i].pickup.address : "",
          c13: logistics[i].pickup !== undefined ? logistics[i].pickup.phoneNumber : "",
          c14: logistics[i].dropoff !== undefined ? logistics[i].dropoff[0].name : "",
          c15: logistics[i].dropoff !== undefined ? logistics[i].dropoff[0].address : "",
          c16: logistics[i].dropoff !== undefined ? logistics[i].dropoff[0].phoneNumber : "",
          c17: logistics[i].amount !== undefined ? (currencyCode + " " + logistics[i].amount) : "",
          c18: logistics[i].dropoff !== undefined ? logistics[i].dropoff[0].notes : "",
          c19: logistics[i].deliveryType,
          c20: logistics[i].trackingLink
        }

        rows.push(temp);
      }
    }
    else {
      rows.push({
        id: "",
        c1: "",
        c2: "",
        c3: "",
        c4: "",
        c5: "",
        c6: "",
        c7: "",
        c8: "",
        c9: "",
        c10: "",
        c11: "",
        c12: "",
        c13: "",
        c14: "",
        c15: "",
        c16: "",
        c17: "",
        c18: "",
        c19: ""
      })
    }

    return rows;
  }
  
  const fetchData = async (pageStart, pageLimit, filterData) => {
    
    setPageState(old => ({ ...old, isLoading: true }))
    let searchParameters = createFilterQuery(filterData);
    let page = (pageStart - 1) * pageLimit;
    let pageSize = pageLimit;
    const response = await getRecords(searchParameters, page, pageSize, _user, dispatch, 'no')
    let logistics = response.data
    const rows = transformDbDataToGrid(logistics);
    setPageState(old => ({ ...old, isLoading: false, data: rows, total: response.total }));
  }

  useEffect(() => {
    if (_user.loginState === CONSTANTS.LOGIN_STATE.yes) {
      fetchData(pageState.page, pageState.pageSize, filterData)
    }
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_user.loginState, pageState.page, pageState.pageSize])

  const onSearchTriggered = async (filterData) => {
    setFilterData(filterData)
    
    setPageState({
      isLoading: false,
      data: [],
      total: 0,
      page: 1,
      pageSize: 10,
      isFilterSelected: false
    });

    fetchData(1, 10, filterData);
  }

  const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    'SaveAlt',
  );

  function CustomToolbar() {  
    const buttonBaseProps = {
      color: 'primary',
      size: 'small',
      startIcon: <ExportIcon />,
    };

    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <Button
          {...buttonBaseProps}
          onClick={handleExport}
          disabled={pageState.isLoading}
        >
          Export
        </Button>
      </GridToolbarContainer>
    );
  }

  return <>
    <Typography variant="h5" style={styles.headerStyle}>Logistics Dashboard</Typography>
    <div style={styles.dashboardStyle}>

      <DashboardFilter onSearchTriggered={onSearchTriggered} distinctSearchFilters={distinctSearchFilters}/>

      <DataGrid
        autoHeight
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              c1: false,
              c2: false,
              c8: false,
              c9: false,
              c10: false,
            },
          },
        }}
        sx={styles.dataGridStyle}
        rows={pageState.data}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        pagination
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState(old => ({ ...old, page: newPage + 1 }))
        }}
        onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
        columns={columns}
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true
          }
        }}
      />
      <CancellationModal cancellationState={cancellationState} setCancellationState={setCancellationState} handleClose={handleClose}/>
    </div>
  </>;
}