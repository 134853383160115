import { get, del } from "../utils/ApiUtils";
import { LS_LOGISTICS_PATH, LS_TRACKING_PATH } from "../utils/Constants"
import { getAuthHeaders } from "./AuthService";

export const getRecords = async (searchParameters, page, pageSize, user, dispatch, isExport)=>{
    let url = `${process.env.REACT_APP_SERVER_URL}${LS_LOGISTICS_PATH}?page=${page}&limit=${pageSize}${searchParameters}&isExport=${isExport}`
    let accessToken = user.accessToken;
    let authHeaders = getAuthHeaders(accessToken);
    const response = await get(url,
        {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": '*',
          ...authHeaders
        }, dispatch, user
      );
      return response.result;
}

export const cancelOrder = async (logisticsId, user, dispatch)=>{
  try {
    let url = `${process.env.REACT_APP_SERVER_URL}?id=${logisticsId}&cancellationReason=Cancelled by LMS&isManuallyCancelled=true`
    let authHeaders = getAuthHeaders(user.accessToken);
    const response = await del(url,
      {
        "Content-Type": "application/json",
        ...authHeaders
      }, dispatch, user
    );
    return response;
  } catch (error) {
    return undefined
  }
}

export const getTrackingSteps = async (logisticsId, language)=>{
    let url = process.env.REACT_APP_SERVER_URL + LS_TRACKING_PATH + "?id=" + logisticsId + "&languageCode=" + language;
    const response  = await get(url);
    return response;
}